import { capitalize, debounce } from 'lodash';
import { DateTime } from 'luxon';

const ISO_DATE_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

export const convertISOToDates = (obj: any): void => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'object') {
      convertISOToDates(value);
    } else if (
      typeof value === 'string' &&
      ISO_DATE_REGEX.test(value) &&
      DateTime.fromISO(value).isValid
    ) {
      obj[key] = DateTime.fromISO(value).toJSDate();
    }
  }
};

export const debouncedInput = debounce((fn: () => void) => fn(), 250);

export const getReadableConstant = (constantName: string) =>
  capitalize(constantName.replace(/_/g, ' '));

export const getStringKeysFromEnum = (e: object): string[] =>
  Object.keys(e).filter((key) => isNaN(Number(key)));

export const pluralize = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural;

export const sanitizeMessageHtml = (message: string): string => {
  const regex = /<p><\/p>/g;
  return message.replace(regex, '<p>&nbsp;</p>');
};

export const wait = (ms = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const poll = async (
  fn: () => Promise<any>,
  fnCondition: (result: any) => boolean,
  ms: number
) => {
  let result = await fn();
  while (fnCondition(result)) {
    await wait(ms);
    result = await fn();
  }
  return result;
};

export const getRandomNumberBetween = ({
  min,
  max,
}: {
  min: number;
  max: number;
}) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getRelativeTime = ({
  date,
  zone = 'utc',
}: {
  date: Date;
  zone?: string;
}) => {
  const diffInSeconds = Math.abs(
    DateTime.fromJSDate(date, { zone }).diffNow('seconds').seconds
  );
  const diffUnderMinute = Math.abs(diffInSeconds) < 60;

  if (diffUnderMinute && diffInSeconds < 0) {
    return 'a few moments ago';
  }

  return DateTime.fromJSDate(date, { zone }).toRelative({
    unit: ['hours', 'minutes', 'seconds'],
  });
};
