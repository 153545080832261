import { useEffect, useState, type PropsWithChildren } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@/theme/index';

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [isHydrated, setIsHydrated] = useState<boolean>(false);

  useEffect(() => setIsHydrated(true), []);

  if (!isHydrated) {
    return null;
  }

  return (
    <ChakraProvider resetCSS theme={theme}>
      {children}
    </ChakraProvider>
  );
};
